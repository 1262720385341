import { Icon, Link, Tooltip } from "@mui/material";
import { GridColDef, gridFilteredSortedRowIdsSelector } from "@mui/x-data-grid-pro";
import { DateRange } from "@mui/x-date-pickers-pro";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { FORMAT_DATE, OPERATION_API, OPERATION_TYPE_MAP, OperationEntry, OperationType } from "../../@types/operation";
import ICONS from "../../assets/icons";
import ExportButton from "../../components/ExportButton";
import IconProductType from "../../components/IconProductType";
import SwitchButton from "../../components/SwitchButton";
import { DateFilter } from "../../components/datagrid/DateFilter";
import { HOST_BO_API } from "../../config";
import ListPage from "../../layouts/ListPage";
import { PATH_CLIENT, PATH_HOME, PATH_OPERATION, PATH_PRODUIT } from "../../routes/paths";
import { momentToDateWithoutTime } from "../../utils/formatDate";
import { getListExportExcel } from "../../utils/toolsPost";
import { CLIENT_API, Client } from "../../@types/client";
import axiosInstance from "../../utils/axios";
import { PRODUCT_CODE_MAP } from "../../@types/product";
import { SITE_API, Site } from "../../@types/site";

// ----------------------------------------------------------------------
export default function OperationListPage() {
  const [period, setPeriod] = useState<DateRange<Moment>>([
    moment().subtract(1, "month").add(1, "days"),
    moment(),
  ]);
  const [productType, setProductType] = useState("")
  const [apiRef, setApiRef] = useState();
  const [clients, setClients] = useState<Client[]>([]);
  const [sites, setSites] = useState<Site[]>([]);

  useEffect(() => {
    axiosInstance
      .get(CLIENT_API)
      .then((response: any) => setClients(response.data));
  }, [])

  useEffect(() => {
    axiosInstance
      .get(SITE_API)
      .then((response: any) => setSites(response.data));
  }, []);

  const columns: GridColDef<OperationEntry>[] = useMemo(
    () => {
      const iconSwitch = <Icon>{ICONS.switch}</Icon>;

      return ([
        {
          field: "date",
          headerName: "Date",
          editable: false,
          flex: 1,
          type: "date",
          renderCell: (params: any) => {
            let m = moment(params.value);
            return <Link sx={{ fontWeight: "bold" }} href={PATH_OPERATION.root + "/" + params.row.id}>{m.format(FORMAT_DATE)}</Link>;
          },
        },
        {
          field: "type",
          headerName: "Type",
          editable: false,
          flex: 0.5,
          valueGetter: (params: any) => OPERATION_TYPE_MAP[params.value as OperationType]
        },
        // {
        //   field: "client_name",
        //   headerName: "Client",
        //   editable: false,
        //   flex: 1,
        //   renderCell: (params: any) => <Link href={PATH_CLIENT.root + "/" + params.row.client_id}>{params.value}</Link >
        // },
        {
          field: "productTypeIco",
          headerName: "",
          editable: false,
          flex: 0.25,
          renderCell: (params: any) => <IconProductType code={"" + params.row.product_type} />,
          align: "center",
        },
        {
          field: "product_reference",
          headerName: "Produit",
          editable: false,
          flex: 1.5,

          renderCell: (params: any) => (
            <Link href={PATH_PRODUIT.root + "/" + params.row.product_id
            } >
              {params.value}
            </Link >
          )
        },
        {
          field: "quantity",
          headerName: "Qté",
          editable: false,
          flex: 0.5,
        },
        {
          field: "isSwitch",
          headerName: "Switch",
          renderHeader: () => iconSwitch,
          editable: false,
          flex: 0.25,
          renderCell: (params: any) => ((params.value === true) ? iconSwitch : <></>)
        },
        {
          field: "prestation",
          headerName: "Prestation",
          editable: false,
          flex: 1,
          valueGetter: (params) => (params.row.type.toString() === OperationType.IN) ? params.row.destination : "",
        },
        {
          field: "destination",
          headerName: "Destination",
          editable: false,
          flex: 1,
          valueGetter: (params) => (params.row.type.toString() === OperationType.OUT) ? params.row.destination : "",
        },
        {
          field: "customs",
          headerName: "Douane",
          editable: false,
          flex: 1,
        },
        {
          field: "site_name",
          headerName: "Site",
          editable: false,
          flex: 1,
          renderCell: (params: any) => <Link href={PATH_CLIENT.root + "/" + params.row.site_id}>{params.value}</Link >
        },
        {
          field: "comment",
          headerName: "",
          editable: false,
          flex: 0.25,
          renderCell: (params: any) => (params.value) ? <Tooltip title={params.value}><Icon>{ICONS.withComment}</Icon></Tooltip> : null,
          align: "center",
        },
        {
          field: "user_name",
          headerName: "Auteur",
          editable: false,
          flex: 1,
        }
      ]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const getFilter = () => {
    let end = period[1]?.clone()
    end?.add(1, "days")
    let filter = "date_start=" + momentToDateWithoutTime(period[0]) + "&date_end=" + momentToDateWithoutTime(period[1])
    if (productType !== "") {
      filter += "&product_type=" + productType
    }
    return filter
  }

  return (
    <ListPage
      title="Liste des opérations"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Liste des opérations" },
      ]}
      columns={columns}
      searchFunc={searchFunc}
      pathNew={PATH_OPERATION.new}
      service={OPERATION_API}
      getListMethod="list"
      urlFilterText={getFilter()}
      canCreate={true}
      headerActions={[
        <SwitchButton key="switch" />,
        <ExportButton launchFct={() => getListExportExcel(OPERATION_API, period, apiRef, "operations.xlsx")} key="export" />,
      ]}
      filterComponents={[<DateFilter period={period} setPeriod={setPeriod} key="df" />]}
      setApiRef={setApiRef}
      apiRef={apiRef}

      filters={[
        {
          name: "product_type",
          type: "select",
          label: "Type",
          items: Object.entries(PRODUCT_CODE_MAP).map(([key, value]) => ({ value: key, label: value }))
        },
        {
          name: "site_id",
          type: "select",
          label: "Site",
          items: sites.map(s => ({ value: s.id + "", label: s.name! }))
        },
        {
          name: "client_id",
          type: "select",
          label: "Client",
          items: clients.map(s => ({ value: s.id + "", label: s.name! }))
        },
      ]}
    />
  );
}

function searchFunc(ope: OperationEntry, filterValue: string) {
  filterValue = filterValue.toLowerCase();

  return (
    ope.product_reference.toLowerCase().indexOf(filterValue) !== -1 ||
    (ope.destination && ope.destination?.toLowerCase().indexOf(filterValue) !== -1) ||
    (ope.customs && ope.customs?.toLowerCase().indexOf(filterValue) !== -1) ||
    ope.quantity.toString().indexOf(filterValue) !== -1 ||
    ope.site_name.toLowerCase().indexOf(filterValue) !== -1 ||
    ope.user_name.toLowerCase().indexOf(filterValue) !== -1 ||
    OPERATION_TYPE_MAP[ope.type as OperationType]?.toLowerCase().indexOf(filterValue) !== -1
  );
}
