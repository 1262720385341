import { Button, Icon, Tooltip } from "@mui/material";
import ICONS from "../assets/icons";
import { dateTimeDotNetToLogDateTime } from "../utils/formatDate";
import { AvancementPrestation } from "../@types/prestation";

interface IconBlockedProps {
    isChecked?: boolean,
    avancementPrestation?: AvancementPrestation
    tooltipMsg?: string
    modeValidation: boolean
    onClick?: any
}

export default function IconIsCheck(props: IconBlockedProps) {
    const { isChecked, tooltipMsg, avancementPrestation, onClick, modeValidation } = props

    if (isChecked == undefined) {
        return <></>
    }

    let icon = (isChecked) ? <Icon color="success">{ICONS.check}</Icon> : <Icon color="error">{ICONS.close}</Icon>

    if (onClick) {
        icon = <Button onClick={() => onClick()}>{icon}</Button>
    } else {
        icon = <Button>{icon}</Button>
    }

    let title = tooltipMsg
    if (!tooltipMsg && avancementPrestation) {

        if (modeValidation) {
            if (avancementPrestation.isValid) {
                title = "validé par " + avancementPrestation.validBy + " le " + dateTimeDotNetToLogDateTime(avancementPrestation.validDate)
            } else {
                if (avancementPrestation.removeValidBy && avancementPrestation.removeValidDate) {
                    title = "validation supprimée par " + avancementPrestation.removeValidBy + " le " + dateTimeDotNetToLogDateTime(avancementPrestation.removeValidDate)
                }
            }
        } else {

            if (avancementPrestation.isChecked) {
                title = "contrôlé par " + avancementPrestation.checkedBy + " le " + dateTimeDotNetToLogDateTime(avancementPrestation.checkedDate)
            } else {
                if (avancementPrestation.uncheckedBy && avancementPrestation.uncheckedDate) {
                    title = "contrôle supprimé par " + avancementPrestation.uncheckedBy + " le " + dateTimeDotNetToLogDateTime(avancementPrestation.uncheckedDate)
                }
            }
        }
    }

    if (title) {
        return <Tooltip title={title} >{icon}</Tooltip>
    }

    return icon
};