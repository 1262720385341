import { Icon, Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import ICONS from "../../../assets/icons";
import ListPage from "../../../layouts/ListPage";
import { PATH_ADMIN, PATH_HOME } from "../../../routes/paths";
import { FILE_TEMPLATE_API, searchFileTemplate } from "../../../@types/fileTemplate";
import { fData } from "../../../utils/formatNumber";
import { dateTimeDotNetToDateTime } from "../../../utils/formatDate";

// ----------------------------------------------------------------------
export default function FileTemplateListPage() {


  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "mediaId",
        headerName: "PJ",
        flex: 0.25,
        renderCell: (params) => params.value && <Icon color="disabled">{ICONS.attachment}</Icon>
      },
      {
        field: "name",
        headerName: "Nom du modèle",
        flex: 1,
        renderCell: (params: any) => (
          <Link
            sx={{ fontWeight: "bold" }}
            href={PATH_ADMIN.file_template.root + "/" + params.row.id}
          >
            {params.value}
          </Link>
        ),
      },
      {
        field: "size",
        headerName: "Taille",
        flex: 0.25,
        valueGetter: (params: any) => (params.row.media == null) ? "" : fData(params.row.media.size)
      },
      {
        field: "media",
        headerName: "Nom du fichier",
        flex: 1,
        valueGetter: (params: any) => (params.value == null) ? "" : params.value.originalFilename
        ,
      },
      {
        field: "lastModifiedDate",
        headerName: "Dernière modification",
        flex: 1,
        renderCell: (params: any) => (
          <>
            {dateTimeDotNetToDateTime(params.value)}
          </>
        ),
        //valueGetter: (params: any) => (params.value == null) ? "" : params.value.originalFilename

      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );

  return (
    <ListPage
      title="Liste des modèles d'export"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Admin" },
        { name: "Liste des modèles d'export" },
      ]}
      columns={columns}
      searchFunc={searchFileTemplate}
      pathNew={PATH_ADMIN.file_template.new}
      service={FILE_TEMPLATE_API}
      deletable={true}
    />
  );
}
