import { Button, ClickAwayListener, MenuItem, MenuList, Paper, Popover } from "@mui/material";
import { ExcelIcon } from "./image/CustomIcon";
import { useRef, useState } from "react";
import { exportExcel } from "../utils/getFileData";

export interface ExportActionProps {
    label: string
    launchFct?: () => void
    api?: string
    fileName?: string
}

interface ExportsButtonProps {
    name?: string
    actions: ExportActionProps[]
}

export default function ExportsButton(props: ExportsButtonProps) {
    const { name, actions } = props
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleClose = () => setOpen(false)

    return (
        <>
            <Button
                ref={anchorRef}
                variant="contained"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={() => setOpen(!open)}
                startIcon={<ExcelIcon />}
            > {name ?? "Export"}</Button>
            <Popover
                open={open}
                role={undefined}
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                        >
                            {actions.map((action: ExportActionProps, index: number) => <MenuItem key={"action" + index} onClick={() => {
                                setOpen(false)
                                if (action.launchFct) action.launchFct()
                                else if (action.api && action.fileName) exportExcel(action.api, action.fileName)

                            }}>{action.label}</MenuItem>)}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popover >
        </>)
}