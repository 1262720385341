// @mui
import { alpha } from '@mui/material/styles';
import { IconButton, Stack, Typography, Box, Icon, Link } from '@mui/material';
import { fData } from '../../../utils/formatNumber';
import FileThumbnail, { fileData } from '../../file-thumbnail';
import ICONS from '../../../assets/icons';
import { Media } from '../../../@types/media';

// ----------------------------------------------------------------------

interface FilePreviewProps {
  thumbnail: any
  file: string | File | Media
  index: number
  onRemove?: any
}

export default function FilePreview(props: FilePreviewProps) {
  const { thumbnail, file, index, onRemove } = props
  const { key, name = '', size = 0, preview = "" } = fileData(file);
  const isNotFormatFile = typeof file === 'string';

  if (thumbnail) {
    return (
      <Stack
        key={index}
        alignItems="center"
        display="inline-flex"
        justifyContent="center"
        sx={{
          m: 0.5,
          width: 80,
          height: 80,
          borderRadius: 1.25,
          overflow: 'hidden',
          position: 'relative',
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <Link href={preview} target='_blank'  >
          <FileThumbnail
            tooltip
            imageView
            file={file}
            sx={{ position: 'absolute' }}
            imgSx={{ position: 'absolute' }}
          />
        </Link>

        {onRemove && (
          <IconButton
            size="small"
            onClick={() => onRemove(file)}
            sx={{
              top: 4,
              right: 4,
              p: '1px',
              position: 'absolute',
              color: (theme) => alpha(theme.palette.common.white, 0.72),
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
              '&:hover': {
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              },
            }}
          >
            <Icon sx={{ width: 16 }}>{ICONS.close}</Icon>
          </IconButton>
        )}
      </Stack>
    );
  }

  return (
    <Stack
      key={key ?? index}
      direction="row"
      alignItems="center"
      sx={{
        my: 1,
        px: 1,
        py: 0.75,
        borderRadius: 0.75,
        border: (theme) => `solid 1px ${theme.palette.divider}`,
      }}
    >
      <Link flexGrow={1} href={preview} target='_blank' >
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
        >
          <FileThumbnail file={file} />

          <Stack flexGrow={1} sx={{ minWidth: 0 }}>
            <Typography variant="subtitle2" noWrap>
              {isNotFormatFile ? file : name}
            </Typography>

            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {isNotFormatFile ? '' : fData(size)}
            </Typography>
          </Stack>
        </Stack>
      </Link>

      {
        onRemove && (
          <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
            <Icon>{ICONS.close}</Icon>
          </IconButton>
        )
      }
    </Stack>
  );

}
