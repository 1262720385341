import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormCols, FormWithMethods } from "../../../components/hook-form/Form";
import { useForm } from "react-hook-form";

import { Icon } from "@mui/material";
import ICONS from "../../../assets/icons";
import Page from "../../../layouts/Page";
import { PATH_HOME, PATH_ADMIN } from "../../../routes/paths";
import axiosInstance from "../../../utils/axios";
import { FILE_TEMPLATE_API, FileTemplate } from "../../../@types/fileTemplate";

// ----------------------------------------------------------------------
export default function FileTemplatePage() {
  const params = useParams();
  const [fileTemplate, setFileTemplate] = useState<FileTemplate | undefined>(undefined);

  useEffect(() => {
    console.log("FileTemplatePage - useEffect - fileTemplate : ", fileTemplate)
    //pour récupérer les données
    if (params.id !== undefined)
      axiosInstance
        .get(FILE_TEMPLATE_API + "/" + params.id)
        .then((response: any) => initData(response.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const initData = useCallback((newData: FileTemplate) => {
    setFileTemplate(newData);
  }, []);


  const schema = Yup.object().shape({
    name: Yup.string().required("Nom obligatoire").max(50),
    comment: Yup.string(),
  });


  const defaultValues = useMemo(() => {
    console.log("FileTemplatePage - useMemo - fileTemplate : ", fileTemplate)
    return ({
      id: fileTemplate?.id ?? undefined,
      name: fileTemplate?.name ?? "",
      comment: fileTemplate?.comment ?? "",
      upload_photo: fileTemplate?.upload_photo ?? fileTemplate?.media,
      media: fileTemplate?.media,
    } as FileTemplate)
  }
    , [fileTemplate]
  );

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const formCols: FormCols<FileTemplate>[] = useMemo(
    () => [
      {
        elements: [
          [
            { name: "name", label: "Nom" },
            { name: "comment", label: "Commentaire" },
            { name: "upload_photo", label: "PJ", title: "Pièce jointe", type: "file", accept: "", required: true },
          ],
        ],
      },
    ],
    []
  );


  //const title = useMemo(() => params.id ? "Modèle d'export : " + fileTemplate?.name : "Nouveau modèle d'export", [fileTemplate]);
  const title = params.id
    ? "Modèle d'export : " + fileTemplate?.name
    : "Nouveau modèle d'export";

  return (
    <Page
      title={title}
      HeaderProps={{
        links: [
          {
            name: "Accueil",
            href: PATH_HOME,
            icon: <Icon>{ICONS.home}</Icon>,
          },
          { name: "Liste des modèles d'export", href: PATH_ADMIN.file_template.root },
          { name: "Modèle d'export" },
        ],
      }}
      withContainer
    >
      <FormWithMethods<FileTemplate, Yup.AnyObjectSchema>
        methods={methods}
        formCols={formCols}
        schema={schema}
        submitButtonProps={{ fullWidth: true, size: "large" }}
        service={FILE_TEMPLATE_API}
        obj={fileTemplate}
        setObj={initData}
        isNew={!params.id}
        isEdit={!!params.id}
        defaultValues={defaultValues}
      />

    </Page>
  );
}
