import { Button } from "@mui/material";
import { ExcelIcon } from "./image/CustomIcon";
import { HOST_BO_API } from "../config";
import axiosInstance from "../utils/axios";
import { AxiosResponse } from "axios";
import { exportExcel } from "../utils/getFileData";

interface ExportButtonProps {
    launchFct?: () => void
    api?: string
    name?: string
    fileName?: string
}

export default function ExportButton(props: ExportButtonProps) {
    const { launchFct, api, name, fileName } = props

    return (
        <Button
            variant="contained"
            onClick={() => {
                if (launchFct) launchFct()
                else if (api && fileName) exportExcel(api, fileName)
            }}
            startIcon={< ExcelIcon />}
        > {name ?? "Export"}</Button >)
}