import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { CLIENT_API, Client } from "../../@types/client";
import {
  PRESTATION_API,
  Prestation,
} from "../../@types/prestation";
import { PRODUCT_API, ProductEntry } from "../../@types/product";
import { SITE_API, Site, SiteLight } from "../../@types/site";
import { FormCols, FormWithMethods } from "../../components/hook-form/Form";
import axiosInstance from "../../utils/axios";
import ProductPrestationDetails from "./ProductPrestationDetails";
import { DEFAULT_PRODUCT_STOCK } from "./StockProductList";
import ICONS from "../../assets/icons";
import { downloadFile } from "../../utils/toolsPost";
import { usePrestationContext } from "../../contexts/PrestationContext";
import { PRESTATION_TYPE_API, PrestationType } from "../../@types/PrestationType";

interface Props {
  isNew?: boolean;
}

export default function PrestationInformations({ isNew }: Props) {
  const [typesPrest, setTypesPrest] = useState<PrestationType[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [products, setProducts] = useState<ProductEntry[]>([]);
  const [sites, setSites] = useState<Site[]>([]);
  const { prestation, setPrestation } = usePrestationContext()

  const schema = useMemo(() => (
    Yup.object().shape({
      reference: Yup.string().max(50),
      begin_date: Yup.date().nullable(),
      end_date: Yup.date().nullable(),
      type: Yup.object().nullable().required("Type de prestation obligatoire"),
      client: Yup.object().nullable(),
      site: Yup.object().nullable().required("Site obligatoire"),
      cellar: Yup.object().nullable(),
      products: Yup.array().of(
        Yup.object().shape({
          product: Yup.object().nullable().required("Produit obligatoire"),
          quantity: Yup.number().integer("Qté non entière").typeError("Qté obligatoire"),
        })
      )
    })
  ), []);

  const defaultValues = useMemo(
    () => ({
      id: prestation?.id ?? undefined,
      title: prestation?.title ?? "",
      reference: prestation?.reference ?? "",
      type: prestation?.type ?? "",
      comment: prestation?.comment ?? "",
      begin_date: prestation?.begin_date ?? moment().toDate(),
      end_date:
        prestation?.end_date ?? moment().add(1, "month").add(2, "days").toDate(),
      client: prestation?.client ?? "",
      products: prestation?.products ?? [DEFAULT_PRODUCT_STOCK],
      productsDestination: prestation?.productsDestination ?? [],
      avancements: prestation?.avancements ?? [],
      site: prestation?.site ?? "",
      cellar: prestation?.cellar ?? "",
      medias: prestation?.medias ?? undefined,
      upload_photos: prestation?.upload_photos ?? prestation?.medias,
      kercoat: prestation?.kercoat ?? false
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prestation]
  );

  const methods = useForm<Prestation>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { watch, setValue } = methods;
  const typeWatch = watch("type");
  const clientWatch = watch("client");
  const productWatch = watch("products");
  const siteWatch = watch("site");
  const cellarWatch = watch("cellar");

  useEffect(() => {
    //pour récupérer les données
    axiosInstance
      .get(PRESTATION_TYPE_API)
      .then((response: any) => setTypesPrest(response.data));

    axiosInstance
      .get(CLIENT_API)
      .then((response: any) => setClients(response.data));

    axiosInstance
      .get(PRODUCT_API + "/ListForSelect")
      .then((response: any) => setProducts(response.data));

    axiosInstance
      .get(SITE_API)
      .then((response: any) => setSites(response.data));
  }, []);

  const prestaTypesItems = useMemo(
    () => typesPrest.map((tp) => ({ id: tp.id, nom: tp.title, isTri: tp.isTri })),
    [typesPrest]
  );
  const clientItems = useMemo(
    () => clients.map((c) => ({ id: c.id, nom: c.name })),
    [clients]
  );
  const filteredProducts = useMemo(
    () => products.filter(p => clientWatch ? p.client_id === clientWatch?.id : true),
    [products, clientWatch]
  );
  const sitesItems = useMemo(
    () => sites.map((s) => ({ id: s.id, nom: s.name, cellars: s.cellars })),
    [sites]
  );
  const cellarsItems = useMemo(
    () => sites.find(s => s.id === (siteWatch as SiteLight)?.id)?.cellars?.map((c) => ({ id: c.id, nom: c.name })),
    [sites, siteWatch]
  );

  useEffect(
    () => {
      if (cellarWatch && (!siteWatch || !sites?.find(s => s.id === siteWatch?.id)?.cellars?.some(c => c.id === cellarWatch.id)))
        setValue("cellar", "");
    },
    [siteWatch]
  );

  const formCols: FormCols<Prestation>[] = useMemo(() => ([
    {
      lg: 7,
      xs: 12,
      elements: [
        [
          <Typography variant="subtitle1" sx={{ color: "primary", mb: 1 }}>
            Informations
          </Typography>,
          {
            name: "type",
            label: "Type de prestation",
            type: "select",
            mode: "object",
            selectOptions: prestaTypesItems,
            xs: 6,
          },
          { name: "reference", label: "Numéro de commande", xs: 6, },
          {
            name: "begin_date",
            label: "Début",
            xs: 3,
            type: "date",
            required: false,
          },
          {
            name: "end_date",
            label: "Fin",
            xs: 3,
            type: "date",
            required: false,
          },
          {
            name: "kercoat",
            label: "Kercoat",
            xs: 6,
            type: "switch",
          },
          {
            name: "site",
            label: "Site",
            xs: 6,
            type: "select",
            mode: "object",
            selectOptions: sitesItems,
          },
          {
            name: "cellar",
            label: "Emplacement",
            xs: 6,
            type: "select",
            mode: "object",
            selectOptions: cellarsItems,
            disabled: siteWatch === "",
            required: false,
          },
          {
            name: "comment",
            label: "Commentaire",
            options: { multiline: true, minRows: 3 },
            required: false,
          },
          // <img src={prest?.photo ? HOST_API + prest.photo.url : "/illustration_blank_image.png"} alt="photo de la prestation" style={{ width: "100%" }} />,
          {
            name: "upload_photos",
            label: "PJ",
            type: "files",
            required: false,
            accept: '',
            title: "Pièce(s) jointe(s)"
          },
        ],
      ],
    },
    {
      lg: 5,
      xs: 12,
      elements: [
        [
          <Typography variant="subtitle1" sx={{ color: "primary", mb: 1 }}>
            Produits
          </Typography>,
          {
            name: "client",
            label: "Client associé",
            type: "select",
            mode: "object",
            selectOptions: clientItems,
            required: false,
          },
          <ProductPrestationDetails products={filteredProducts} methods={methods} />
        ]],
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ]), [clientItems, prestaTypesItems, clientWatch, productWatch, prestation, prestation?.medias, products, typeWatch, filteredProducts, siteWatch]);

  return (
    <>
      <FormWithMethods<Prestation, Yup.AnyObjectSchema>
        formCols={formCols}
        methods={methods}
        submitButtonProps={{ fullWidth: true, size: "large" }}
        obj={prestation}
        setObj={setPrestation}
        isNew={isNew}
        isEdit={!isNew}
        defaultValues={defaultValues}
        service={PRESTATION_API}
        withPaper
        otherButton={(prestation) ? { name: "Export Excel", icon: ICONS.downloadFile, fct: () => downloadFile(PRESTATION_API + "/export/" + prestation.id, "Prestation-" + prestation.reference + ".xlsx") } : undefined}
      />
    </>
  );
}
