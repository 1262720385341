import { GridColDef, GridValueSetterParams } from "@mui/x-data-grid-pro";
import { useMemo, useState } from "react";
import { AllOperationType } from "../../@types/operation";
import { AVANCEMENT_PRESTATION_API, AvancementPrestation, PRESTATION_API } from "../../@types/prestation";
import { ProductLight, getProductOptionLabel } from "../../@types/product";
import List from "../../components/datagrid/List";
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import GridEditDateCell from "../../components/datagrid/GridEditDateCell";
import moment from "moment";
import { Button, Icon, Tooltip } from "@mui/material";
import ICONS from "../../assets/icons";
import ExportButton from "../../components/ExportButton";
import { downloadFile } from "../../utils/toolsPost";
import { usePrestationContext } from "../../contexts/PrestationContext";
import { PrestationType } from "../../@types/PrestationType";
import IconIsCheck from "../../components/IconIsCheck";
import axiosInstance from "../../utils/axios";
import ConfirmDialog from "../../components/confirm-dialog";
import { useSnackbar } from "notistack";
import { dateTimeDotNetTo } from "../../utils/formatDate";
import ExportsButton, { ExportActionProps } from "../../components/ExportsButton";


const dateAdapter = new AdapterMoment({ locale: "fr" });

// ----------------------------------------------------------------------
export default function AvancementsPrestationList() {
  const { prestation, setPrestation } = usePrestationContext()
  const [showConfirm, setShowConfirm] = useState<AvancementPrestation>()
  const [confirmValidation, setConfirmValidation] = useState<boolean>()
  const { enqueueSnackbar } = useSnackbar();

  const IconChecked = (params: any) => {
    return <IconIsCheck modeValidation={false} isChecked={params.value} onClick={() => { setShowConfirm(params.row); setConfirmValidation(false) }} avancementPrestation={params.row} />
  }

  const IconValid = (params: any) => {
    return <IconIsCheck modeValidation={true} isChecked={params.value} onClick={() => { setShowConfirm(params.row); setConfirmValidation(true) }} avancementPrestation={params.row} />
  }

  const SendCheck = async () => {
    console.log("Check - id : " + showConfirm?.id)
    if (showConfirm) {

      let url = AVANCEMENT_PRESTATION_API + "/" + ((confirmValidation) ? "valid" : "check")
      let currentValue = ((confirmValidation) ? showConfirm.isValid : showConfirm.isChecked)

      await axiosInstance.put(url, { id: showConfirm.id, check: !currentValue }).then((res: any) => {
        if (prestation) {
          let newPrestation = { ...prestation }
          newPrestation.avancements = prestation.avancements?.map((av: AvancementPrestation) => (av.id === showConfirm?.id) ? res.data.avancementPrestation : av) ?? [res.avancementPrestation]
          setPrestation(newPrestation)
        }

        enqueueSnackbar(
          res.data.msg,
          { variant: "success" }
        );
      }).catch((e: any) => {
        console.log("e : ", e)
        enqueueSnackbar(
          "Erreur : " + e,
          { variant: "error" }
        );
      })
      setShowConfirm(undefined)

    }

  }

  const columns: GridColDef<AvancementPrestation>[] = useMemo(
    () => {
      let columns: any[] = [
        {
          field: "dateRealisation",
          headerName: "Date",
          editable: true,
          type: "date",
          width: 150,
          // flex: 1,
          resizable: false,
          valueGetter: (params: any) => params.row.total ? "Total" : params.value && new Date(params.value),
          valueFormatter: (params: any) => {
            if (typeof params.value === 'string') {
              return params.value;
            }
            if (params.value) {
              return moment(params?.value).format('ddd DD/MM/YYYY');
            }
            return '';
          },
          renderEditCell: (params: any) => {
            return <GridEditDateCell {...params} />;
          },
        }
      ];

      columns.push(...prestation?.products?.filter(ps => ps.product !== "" && ps.product !== undefined).map((ps, index) => {
        const idProd: number = (ps.product as ProductLight)?.id as number;
        return ({
          field: "operationsProdEntree[" + idProd + "].quantity",
          headerName: (ps.product as ProductLight).reference,
          editable: true,
          sortable: false,
          type: 'number',
          flex: 1,
          valueGetter: (params: any) => params.row.operationsProdEntree[idProd]?.quantity,
          valueFormatter: (params: any) => params.value?.toLocaleString(),
          valueSetter: (params: GridValueSetterParams) => {
            if (params.row.operationsProdEntree[idProd] === undefined) {
              params.row.operationsProdEntree[idProd] = { id: -1, product: ps.product, quantity: 0, type: (prestation?.type !== "" && prestation?.type?.isTri) ? AllOperationType.PREST_IN : AllOperationType.PREST }
            }
            params.row.operationsProdEntree[idProd].quantity = params.value;
            return { ...params.row };
          },
        });
      }) ?? []);

      if ((prestation?.type !== "" && prestation?.type?.isTri) ?? false) {
        columns.push(...prestation?.productsDestination?.map((p, index) => ({
          field: "operationsProdSortie[" + p.id + "].quantity",
          headerName: (p as ProductLight).reference,
          editable: true,
          sortable: false,
          flex: 1,
          type: "number",
          headerClassName: 'header-blue',
          cellClassName: "row-blue",
          valueGetter: (params: any) => params.row.operationsProdSortie[p.id]?.quantity,
          valueFormatter: (params: any) => params.value?.toLocaleString(),
          valueSetter: (params: GridValueSetterParams) => {
            if (params.row.operationsProdSortie[p.id] === undefined) {
              params.row.operationsProdSortie[p.id] = { id: -1, product: p, quantity: 0, type: AllOperationType.PREST_OUT }
            }
            params.row.operationsProdSortie[p.id].quantity = params.value;
            return { ...params.row };
          },
        })) ?? []);

        columns.push({
          field: "diffES",
          editable: true,
          headerName: "",
          sortable: false,
          type: "boolean",
          width: 10,
          resizable: false,
          renderCell: (params: any) => {
            return !params.row.diffES ? <></> : <Tooltip title="La somme des entrées est différente de la somme des sorties"><Icon>{ICONS.warning}</Icon></Tooltip>;
          },
          renderEditCell: (params: any) => {
            if (params.row.total) {
              return <></>;
            }
            let diff = 0;
            prestation?.products?.filter(p => p.product !== "" && p.product !== undefined).forEach((p, index) => {
              const idProd: number = (p.product as ProductLight)?.id as number;
              diff += (params.row.operationsProdEntree[idProd]?.quantity ?? 0);
            });
            prestation?.productsDestination?.forEach((pd, index) => {
              const idProd: number = (pd as ProductLight)?.id as number;
              diff -= (params.row.operationsProdSortie[idProd]?.quantity ?? 0);
            });
            return diff === 0 ? <></> : <Tooltip title="La somme des entrées est différente de la somme des sorties"><Icon>{ICONS.warning}</Icon></Tooltip>;
          }
        })
      }

      columns.push(
        {
          field: "nbMissing",
          headerName: "Manquant",
          editable: true,
          sortable: false,
          type: 'number',
          flex: 0.5,
        },
        {
          field: "nbBroken",
          headerName: "Cassé",
          editable: true,
          sortable: false,
          type: 'number',
          flex: 0.5,
        }
      )

      if ((prestation?.type !== "" && prestation?.type?.withRilsan) ?? false) {
        columns.push(
          {
            field: "nbRilsan",
            headerName: "Nb Rilsan",
            editable: true,
            sortable: false,
            type: 'number',
            flex: 0.5,
          }
        )
      }

      columns.push(
        {
          field: "comment",
          headerName: "Commentaire",
          editable: true,
          sortable: false,
          width: 400,
          flex: 2,
        },
        {
          field: "isChecked",
          headerName: "Contrôle",
          align: "right",
          renderCell: (params: any) => ((params.row.total) ? <IconIsCheck isChecked={params.value} modeValidation={false} /> : IconChecked(params))
        },
        {
          field: "isValid",
          headerName: "Validation",
          align: "right",
          renderCell: (params: any) => ((params.row.total) ? <IconIsCheck isChecked={params.value} modeValidation={true} /> : IconValid(params))
        })

      return columns;
    }, [prestation, prestation?.avancements]
  );

  const onCreate = () => {
    let newAvancement: AvancementPrestation = {
      id: -1,
      archived: false,
      prestationId: -1,
      dateRealisation: new Date(),
      operationsProdEntree: {},
      operationsProdSortie: {}
    };

    //S'il est moins de 10h, on prend la date de la veille  
    // if (moment().hour() < 10) { 
    //   newAvancement.dateRealisation = moment().subtract(1, 'days').toDate();
    // }

    newAvancement.prestationId = prestation?.id!;
    if ((prestation?.type !== "" && prestation?.type?.isTri) ?? false) {
      prestation?.products?.filter(ps => ps.product !== "" && ps.product !== undefined).forEach((ps, index) => newAvancement.operationsProdEntree![(ps.product as ProductLight)?.id as number] = { id: -1, product: ps.product, quantity: 0, type: AllOperationType.PREST_OUT });
      prestation?.productsDestination?.forEach((ps, index) => newAvancement.operationsProdSortie![ps.id as number] = { id: -1, product: ps, quantity: 0, type: AllOperationType.PREST_IN });
    } else {
      prestation?.products?.filter(ps => ps.product !== "" && ps.product !== undefined).forEach((ps, index) => newAvancement.operationsProdEntree![(ps.product as ProductLight)?.id as number] = { id: -1, product: ps.product, quantity: 0, type: AllOperationType.PREST });
    }

    setPrestation({ ...prestation, avancements: [newAvancement, ...prestation?.avancements ?? []] });
  }

  const totalRow: any = useMemo(() => {
    let nbMissing = 0
    let nbBroken = 0
    let nbRilsan = 0
    prestation?.avancements?.map((av) => {
      if (av.nbRilsan) nbRilsan += av.nbRilsan
      if (av.nbMissing) nbMissing += av.nbMissing
      if (av.nbBroken) nbBroken += av.nbBroken
    })
    let isChecked = (prestation?.avancements?.length === 0) ? undefined : prestation?.avancements?.filter((av) => av.isChecked === false).length === 0
    let isValid = (prestation?.avancements?.length === 0) ? undefined : prestation?.avancements?.filter((av) => av.isValid === false).length === 0


    let tr: any = {
      id: "total",
      total: true,
      editable: false,
      operationsProdEntree: {},
      operationsProdSortie: {},
      nbMissing,
      nbBroken,
      nbRilsan,
      isChecked,
      isValid
    }
    prestation?.products?.filter(ps => ps.product !== "" && ps.product !== undefined).forEach(ps => {
      tr.operationsProdEntree[(ps.product as ProductLight)?.id as number] = {
        quantity: prestation.avancements?.reduce((acc, curVal) => acc + ((curVal?.operationsProdEntree![(ps.product as ProductLight)?.id as number]?.quantity as number) ?? 0), 0).toLocaleString() + "/" + ps.quantity.toLocaleString()
      };
    })
    if ((prestation?.type !== "" && prestation?.type?.isTri) ?? false) {
      prestation?.productsDestination?.forEach(p => {
        tr.operationsProdSortie[p?.id as number] = {
          quantity: prestation.avancements?.reduce((acc, curVal) => acc + ((curVal?.operationsProdSortie![p?.id as number]?.quantity as number) ?? 0), 0).toLocaleString()
        };
      });
    }
    return tr;
  }, [prestation?.products, prestation?.productsDestination, (prestation?.type as PrestationType)?.isTri, prestation?.avancements]);

  let validAll = () => {
    axiosInstance.put(AVANCEMENT_PRESTATION_API + "/validAll", { id: prestation?.id }).then((res: any) => {

      let newPrestation = { ...prestation }
      newPrestation.avancements = res.data.avancements
      setPrestation(newPrestation)
    })

  }

  let btnConfirmation = ""
  let txtConfirmation = ""
  let color: any = "primary"
  if (confirmValidation) {
    if (showConfirm?.isValid) {
      btnConfirmation = "Supprimer validation"
      txtConfirmation = "Confirmez vous la suppression de la validation de cette avancement ?"
      color = "error"
    } else {
      btnConfirmation = "Valider"
      txtConfirmation = "Validez vous l'avancement de cette prestation ?"
    }
  } else {
    if (showConfirm?.isChecked) {
      btnConfirmation = "Supprimer contrôle"
      txtConfirmation = "Confirmez-vous la suppression du contrôle de l'avancement de cette prestation"
      color = "error"
    } else {
      btnConfirmation = "Contrôle OK"
      txtConfirmation = "Confirmez-vous le contrôle de l'avancement de cette prestation"
    }
  }


  let actions: ExportActionProps[] = []
  if (prestation) {
    actions = [
      { label: "Format simple avec contrôle", launchFct: () => downloadFile(PRESTATION_API + "/export/" + prestation.id + "/true", "PrestationComplet-" + prestation.reference + ".xlsx") },
      { label: "Format simple sans contrôle", launchFct: () => downloadFile(PRESTATION_API + "/export/" + prestation.id + "/false", "Prestation-" + prestation.reference + ".xlsx") },
    ]

    let isConforme = true
    let isIrrisation = true

    if (prestation.products?.length == 1) {

      let productIn = prestation.products[0].product
      if (productIn) {
        let refIn = productIn.reference

        let refOuts = prestation.productsDestination?.map((pd: any) => pd.reference)

        if (refOuts && refOuts.length > 0) {

          let refOutDifferent = false
          let suffixes: string[] = refOuts?.map((refOut: string) => {
            if (refOut.match(refIn + "-*")) {
              return refOut.substring(refIn.length + 1)
            } else {
              refOutDifferent = true
              return refOut
            }
          })

          if (refOutDifferent === false && suffixes) {
            //console.log("AvancementsPrestationList - suffixes : ", suffixes)
            if (suffixes.filter((s: string) => s !== "CONF" && s !== "NC").length === 0) {
              isIrrisation = false
            }

            if (suffixes.filter((s: string) => s !== "I" && s !== "J" && s !== "Y" && s !== "NC").length === 0) {
              isConforme = false
            }
          }

        }

      }
    }

    if (isConforme) {
      actions.push({ label: "Modèle conforme/non conforme", launchFct: () => downloadFile(PRESTATION_API + "/exportTemplate/" + prestation.id + "/Conforme", "Prestation-" + prestation.reference + ".xlsx") })
    }

    if (isIrrisation) {
      actions.push({ label: "Modèle tri irrisation", launchFct: () => downloadFile(PRESTATION_API + "/exportTemplate/" + prestation.id + "/Irrisation", "Prestation-" + prestation.reference + ".xlsx") })
    }
  }

  return (
    <>
      <ConfirmDialog title={"Avancement du " + dateTimeDotNetTo(showConfirm?.dateRealisation, "dddd DD MMMM YYYY")} action={
        <Button
          variant="contained"
          color={color}
          onClick={() => SendCheck()} > {btnConfirmation}</Button>}
        open={showConfirm !== undefined}
        onClose={() => { setShowConfirm(undefined); setConfirmValidation(undefined) }}
        content={
          <>
            {txtConfirmation}
          </>
        } />

      <List<AvancementPrestation>
        columns={columns}
        rows={prestation?.avancements ? [...prestation.avancements, totalRow] : []}
        setRows={(newRows: AvancementPrestation[]) => setPrestation({ ...prestation, avancements: newRows.filter((nr: any) => nr.total === undefined) })}
        service={AVANCEMENT_PRESTATION_API}
        deletable
        canCreate
        editable
        defaultTableProps={{
          defaultOrder: "desc",
          defaultOrderBy: "dateRealisation"
        }}
        pagination={false}
        filterMenu={false}
        disableColumnReorder
        onCreate={onCreate}
        isCellEditable={(params: any) => params.row.total === undefined}
        getRowClassName={(params: any) => params.row.total !== undefined ? "row-total" : ""}
        sx={{ "& input[type=number]": { textAlign: "right" }, "& .header-blue": { color: theme => theme.palette.primary.main }, "& .MuiDataGrid-row.row-total .MuiDataGrid-cell": { fontWeight: "bold", justifyContent: "flex-end", "&.row-blue": { color: theme => theme.palette.primary.main } } }}
        headerActions={[
          (prestation) ? <ExportsButton key="exports" actions={actions} /> : undefined,
          (prestation && (prestation?.avancements?.filter((av) => !av.isChecked).length ?? 0 > 0)) ? <Button variant="contained" onClick={validAll} startIcon={<Icon >{ICONS.check}</Icon>} key="checkAll"> Valider tout & libérer stock</Button> : undefined,
        ]}
      />
    </>
  );
}