// components
import { AxiosResponse } from "axios";
import { CustomFile } from "../components/upload/types";
import { HOST_BO_API } from "../config";
import axiosInstance from "./axios";

// ----------------------------------------------------------------------

export default function getFileData(file: CustomFile | string, index?: number) {
  if (typeof file === "string") {
    return {
      key: index ? `${file}-${index}` : file,
      preview: file,
    };
  }

  return {
    key: index ? `${file.name}-${index}` : file.name,
    name: file.name,
    size: file.size,
    path: file.path,
    type: file.type,
    preview: file.preview,
    lastModified: file.lastModified,
    lastModifiedDate: file.lastModifiedDate,
  };
}

export function toDataURL(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result + "");
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}


export function exportExcel(api: string, fileName: string) {
  const FileDownload = require('js-file-download');

  let url = HOST_BO_API + "/" + api + "/export"
  axiosInstance.get(url, { responseType: 'blob' }).then((response: AxiosResponse) => {
    FileDownload(response.data, fileName ?? 'export.xlsx');
  })
}