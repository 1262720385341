import React, { useEffect, useState } from "react"
import { Product } from "../@types/product"

interface ProductContextInterface {
    product: Product | undefined
    setProduct: any // (obj: any) => void // () => {}
}

const ProductContext = React.createContext<ProductContextInterface>({
    product: undefined,
    setProduct: (x: any) => { }
})

export const ProductProvider = ({ children }: any) => {
    const [product, setProduct] = useState(undefined)

    return <ProductContext.Provider value={{ product: product, setProduct: setProduct }}>
        {children}
    </ProductContext.Provider>
}

export const useProductContext = () => React.useContext(ProductContext)