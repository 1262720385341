import { Media, UploadPhoto } from "./media";
export const FILE_TEMPLATE_API = "fileTemplates";

export interface FileTemplate {
    id?: number | "";
    name: string
    comment: string
    upload_photo?: UploadPhoto | string | Media;
    media?: Media;
}


export function searchFileTemplate(x: FileTemplate, filterValue: string) {
    filterValue = filterValue.toLowerCase();
    return x.name?.toLowerCase().indexOf(filterValue) !== -1
    // ||        x.comment?.toLowerCase().indexOf(filterValue) !== -1
}
