import { useEffect, useMemo, useState } from "react";
import { Box, Switch, Icon, Link, Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { DateRange } from "@mui/x-date-pickers-pro";
import moment, { Moment } from "moment";
import ICONS from "../../assets/icons";
import ListPage from "../../layouts/ListPage";
import { PATH_CLIENT, PATH_HOME, PATH_PRODUIT } from "../../routes/paths";
import { StockEntry, STOCK_API } from "../../@types/stock";
import { HOST_BO_API } from "../../config";
import { DateFilter } from "../../components/datagrid/DateFilter";
import ExportButton from "../../components/ExportButton";
import { PRODUCT_CODE_MAP, PRODUCT_TYPE_MAP, ProductTypeGetLabel } from "../../@types/product";
import IconProductType from "../../components/IconProductType";
import { momentToDateWithoutTime, momentToFormat } from "../../utils/formatDate";
import { downloadFile, getListExportExcel } from "../../utils/toolsPost";
import axiosInstance from "../../utils/axios";
import { CLIENT_API, Client } from "../../@types/client";
import { SITE_API, Site } from "../../@types/site";
import IconBlocked from "../../components/IconBlocked";
import ExportsButton from "../../components/ExportsButton";

export default function StockListPage() {

    const [period, setPeriod] = useState<DateRange<Moment>>([
        moment().subtract(1, "month").add(1, "days"),
        moment(),
    ]);
    const [inStock, setInStock] = useState(true)
    const [apiRef, setApiRef] = useState();
    const [clients, setClients] = useState<Client[]>([]);
    const [sites, setSites] = useState<Site[]>([]);

    useEffect(() => {
        axiosInstance
            .get(CLIENT_API)
            .then((response: any) => setClients(response.data));
    }, [])

    useEffect(() => {
        axiosInstance
            .get(SITE_API)
            .then((response: any) => setSites(response.data));
    }, []);


    const columns: GridColDef<StockEntry>[] = useMemo(
        () => [
            {
                field: "client_name",
                headerName: "Client",
                editable: false,
                flex: 2,
            },
            {
                field: "product_type_icon",
                headerName: "",
                editable: false,
                flex: 0.25,
                renderCell: (params: any) => <IconProductType code={params.row.product_type} />
            },
            {
                field: "product_type",
                headerName: "Type",
                editable: false,
                flex: 2,
                valueGetter: (params: any) =>
                    ProductTypeGetLabel(params.value)
            },
            {
                field: "product_reference",
                headerName: "Produit",
                editable: false,
                flex: 2,

                renderCell: (params: any) => <Link href={PATH_PRODUIT.root + "/" + params.row.product_id}>{params.value}</Link >
            },
            {
                field: "isBlocked",
                headerName: "",
                editable: false,
                flex: 0.25,
                renderCell: (params: any) => <IconBlocked isBlocked={params.value} />
            },
            {
                field: "site_name",
                headerName: "Site",
                editable: false,
                flex: 1,
                renderCell: (params: any) => <Link href={PATH_CLIENT.root + "/" + params.row.site_id}>{params.value}</Link >
            },
            {
                field: "stock_initial",
                headerName: "Stock initial",
                editable: false,
                flex: 1,
            },
            {
                field: "operations_in",
                headerName: "Entrée",
                editable: false,
                valueGetter: (params: any) => (params.value === 0) ? "-" : params.value,
                flex: 1,
            },
            {
                field: "operations_out",
                headerName: "Sortie",
                editable: false,
                valueGetter: (params: any) => (params.value === 0) ? "-" : params.value,
                flex: 1,
            },
            {
                field: "switchs_in",
                headerName: "Entrée Switch",
                editable: false,
                valueGetter: (params: any) => (params.value === 0) ? "-" : params.value,
                flex: 1,
            },
            {
                field: "switchs_out",
                headerName: "Sortie Switch",
                editable: false,
                valueGetter: (params: any) => (params.value === 0) ? "-" : params.value,
                flex: 1,
            },
            {
                field: "stock_final",
                headerName: "Stock final",
                editable: false,
                flex: 1,
            },
        ],
        []
    );

    // let filters = "date_start=" + period[0]?.toISOString() + "&date_end=" + period[1]?.toISOString() + "&in_stock=" + inStock
    let filters = "date_start=" + momentToDateWithoutTime(period[0]) + "&date_end=" + momentToDateWithoutTime(period[1]) + "&in_stock=" + inStock

    const exportExcelEomv1 = async () => {
        let i = 0;
        for (let site_id = 1; site_id <= 2; site_id++) {
            for (let product_type = 0; product_type <= 1; product_type++) {
                let url = HOST_BO_API + "/" + STOCK_API + "/export_eom?product_type=" + product_type + "&site_id=" + site_id;
                setTimeout(function () {
                    window.open(url)
                }, i * 500);
                i++;
            }
        }
    }

    const exportExcelEom = async () => {
        let i = 0;
        sites.map((site: Site) => {

            for (let productTypeId = 0; productTypeId <= 1; productTypeId++) {
                let productTypeLib: string = ProductTypeGetLabel("" + productTypeId)
                clients.map((client: Client) => {

                    if (client.id !== 2 || productTypeId !== 0) {       // Pour exclure les vins de Fileurope

                        let url = STOCK_API + "/export_eom?product_type=" + productTypeId + "&site_id=" + site.id + "&client_id=" + client.id;
                        let fileName = "Export " + productTypeLib + " " + client.name + " - " + momentToFormat(period[0], "MM-YYYY") + " - " + site.name + ".xlsx";

                        downloadFile(url, fileName)
                    }
                })
            }


        })

    }

    return (
        <ListPage
            title="Reporting des stocks"
            links={[
                { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
                { name: "Reporting des stocks" },
            ]}
            columns={columns}
            getRowId={(r) => r.product_id + "-" + r.site_id}
            searchFunc={searchFunc}
            service={STOCK_API}
            getListMethod=""
            urlFilterText={filters}
            canCreate={false}
            noActions={true}
            headerActions={[
                <ExportsButton actions={[
                    { label: "Liste affichée", launchFct: () => getListExportExcel(STOCK_API, period, apiRef, "Export_Stocks.xlsx") },
                    { label: "Fin de mois", launchFct: exportExcelEom }
                ]} key="exports" />,
                // <ExportButton launchFct={() => getListExportExcel(STOCK_API, period, apiRef, "Export_Stocks.xlsx")} key="export" />,
                // <ExportButton launchFct={exportExcelEom} key="exportEom" name="Fin de mois" />,
                <Stack direction="row" key="FilterInStock"><Switch checked={inStock} color="primary" onChange={() => setInStock(!inStock)} /><Box sx={{ paddingTop: 1 }} >En stock</Box></Stack>,
            ]}
            filterComponents={<DateFilter period={period} setPeriod={setPeriod} />}
            setApiRef={setApiRef}
            apiRef={apiRef}

            filters={[
                {
                    name: "product_type",
                    type: "select",
                    label: "Type",
                    items: Object.entries(PRODUCT_CODE_MAP).map(([key, value]) => ({ value: key, label: value }))
                },
                {
                    name: "site_id",
                    type: "select",
                    label: "Site",
                    items: sites.map(s => ({ value: s.id + "", label: s.name! }))
                },
                {
                    name: "client_id",
                    type: "select",
                    label: "Client",
                    items: clients.map(s => ({ value: s.id + "", label: s.name! }))
                },
            ]}
        />
    );
}

function searchFunc(stock: StockEntry, filterValue: string) {
    filterValue = filterValue.toLowerCase();
    return (
        stock.product_reference.toLowerCase().indexOf(filterValue) !== -1 ||
        stock.site_name.toLowerCase().indexOf(filterValue) !== -1
    );
}
