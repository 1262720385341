import Image from '../../image';
import { CustomFile } from '../types';
import FilePreview from './FilePreview';

// ----------------------------------------------------------------------

type Props = {
  file: CustomFile | string | null;
};

export default function SingleFilePreview({ file }: Props) {
  if (!file) {
    return null;
  }

  if (file.hasOwnProperty('url')) {
    return <FilePreview file={file} index={1} thumbnail={false} />
  }

  if (typeof file !== 'string' && file.type && !file.type.startsWith("image")) {
    return <FilePreview file={file} index={1} thumbnail={false} />
  }

  console.log("SingleFilePreview - file : ", file)

  try {
    const imgUrl = typeof file === 'string' ? file : URL.createObjectURL(file as CustomFile);

    return (
      <Image
        alt="file preview"
        src={imgUrl}
        sx={{
          top: 8,
          left: 8,
          zIndex: 8,
          borderRadius: 1,
          position: 'absolute',
          width: 'calc(100% - 16px)',
          height: 'calc(100% - 16px)',
        }}
      />
    );
  } catch (e: any) {
    console.log("SingleFilePreview - Erreur : ", e)
    return null
  }
}
