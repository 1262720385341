import { Icon, Link } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import { Role } from "../../@types/user";
import ICONS from "../../assets/icons";
import useAuth from "../../hooks/useAuth";
import ListPage from "../../layouts/ListPage";
import { PATH_ADMIN, PATH_HOME, PATH_MAINTENANCE_EVENT } from "../../routes/paths";
import { dateTimeDotNetToDate, dateTimeDotNetToShortDate, dateTimeDotNetToTimeStamp } from "../../utils/formatDate";
import { MAINTENANCE_EVENT_API, MaintenanceEvent, searchMaintenanceEvent } from "../../@types/maintenanceEvent";
import ExportButton from "../../components/ExportButton";
import { MAINTENANCE_API } from "../../@types/maintenance";
import moment from "moment";

// ----------------------------------------------------------------------
export default function MaintenanceEventListPage() {
  const { user, isManager } = useAuth();

  let today = moment()
  let immientDate = moment().add(1, "M")

  const columns: GridColDef<MaintenanceEvent>[] = useMemo(
    () => [
      {
        field: "date",
        headerName: "Date",
        editable: false,
        flex: 1,
        renderCell: (params: any) => (
          <Link sx={{ fontWeight: "bold" }} href={PATH_MAINTENANCE_EVENT.root + "/" + params.row.id}>
            {dateTimeDotNetToShortDate(params.value)}
          </Link>
        ),
      },
      {
        field: "site",
        headerName: "Site",
        editable: false,
        valueGetter: (params: any) => (params.value == null) ? null : params.value?.name,
        flex: 2,
      },
      {
        field: "photos",
        headerName: "PJ",
        editable: false,
        flex: 0.1,
        valueGetter: (params: any) => (params.value.length === 0) ? null : params.value.length
      },
      {
        field: "supplier",
        headerName: "Prestataire",
        editable: false,
        valueGetter: (params: any) => (params.value == null) ? null : params.value?.name,
        flex: 2,
      },
      {
        field: "maintenance",
        headerName: "Opération",
        editable: false,
        renderCell: (params: any) => ((isManager) ?
          <Link href={PATH_ADMIN.maintenance.root + "/" + params.value.id}>
            {params.value?.object}
          </Link> : params.value?.object
        ),
        //valueGetter: (params: any) => (params.value == null) ? null : params.value?.object,
        flex: 6,
      },
      {
        field: "nextDate",
        headerName: "Prochaine",
        editable: false,
        renderCell: (params: any) => {
          let color = "green"
          if (params.value) {
            let m = dateTimeDotNetToTimeStamp(params.value)
            if (params.value && m.isBefore(today)) {
              color = "red"
            } else if (params.value && m.isBefore(immientDate)) {
              color = "orange"
            }
          }

          return <div style={{ color, fontWeight: 'bold' }}> {dateTimeDotNetToDate(params.value)}</div >
        },
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ],
    []
  );


  return (
    <ListPage
      title="Liste des opérations de maintenance"
      links={[
        { name: "Accueil", href: PATH_HOME, icon: <Icon>{ICONS.home}</Icon> },
        { name: "Liste des opérations de maintenance" },
      ]}
      columns={columns}
      searchFunc={searchMaintenanceEvent}
      pathNew={PATH_MAINTENANCE_EVENT.new}
      service={MAINTENANCE_EVENT_API}
      getListMethod="list"
      canCreate={user?.authorities?.some(a => a === Role.ROLE_MANAGER)}
      headerActions={[<ExportButton api={MAINTENANCE_API} key="export" fileName="Maintenances.xlsx" />]}
    />
  );
}


