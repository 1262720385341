import { Alert, Icon, Link, Tooltip } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { DateRange } from "@mui/x-date-pickers-pro";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { CLIENT_API } from "../../@types/client";
import { getOperationTypeName, Operation, OPERATION_API } from "../../@types/operation";
import { PRODUCT_API, PRODUCT_CODE_MAP, PRODUCT_TYPE_MAP } from "../../@types/product";
import { DateFilter } from "../../components/datagrid/DateFilter";
import List from "../../components/datagrid/List";
import { PATH_PRODUIT } from "../../routes/paths";
import axiosInstance from "../../utils/axios";
import ICONS from "../../assets/icons";
import IconProductType from "../../components/IconProductType";
import { SITE_API, Site } from "../../@types/site";

interface ObjectOperationListProps {
  objId: string;
  type?: "Client" | "Product";
}

// ----------------------------------------------------------------------
export default function ObjectOperationList({
  type,
  objId,
}: ObjectOperationListProps) {
  const [operations, setOperations] = useState<Operation[]>([]);
  const [period, setPeriod] = useState<DateRange<Moment>>([
    moment().subtract(1, "month").add(1, "days"),
    moment(),
  ]);

  const [sites, setSites] = useState<Site[]>([]);

  useEffect(() => {
    if (type && type === "Product") {
      // console.log("Cas d'un produit")
      axiosInstance.get(PRODUCT_API + "/" + objId + "/dateOperations").then((response: any) => {
        let data = response.data
        // console.log("Info operation pour ce produit : ", data)

        if (data.nb > 0 && data.nb <= 25) {
          setPeriod([moment(data.dateMin), moment(data.dateMax)])
        }
      })
    }
  }, [type, objId]);

  useEffect(() => {
    axiosInstance
      .get(SITE_API)
      .then((response: any) => setSites(response.data));
  }, []);


  useEffect(() => {
    if (type) {

      axiosInstance
        .get(
          (type === "Client" ? CLIENT_API : PRODUCT_API) +
          "/" +
          objId +
          "/operations?date_start=" +
          period[0]?.toISOString() +
          "&date_end=" +
          period[1]?.toISOString()
        )
        .then((response) => setOperations(response.data));

    } else {
      axiosInstance
        .get(OPERATION_API + "/list?duplicateOnly=true")
        .then((response) => setOperations(response.data));
    }
  }, [type, objId, period]);

  const columns: GridColDef<Operation>[] = useMemo(
    () => {

      const iconSwitch = <Icon>{ICONS.switch}</Icon>;

      return [
        {
          field: "date",
          headerName: "Date",
          type: "dateTime",
          editable: false,
          flex: 1,
          valueGetter: ({ value }) => value && new Date(value),
        },
        {
          field: "type",
          headerName: "Type",
          editable: false,
          flex: 1,
          // valueGetter: (params: any) => params.value === 0 ? "Entrée" : "Sortie",
          valueGetter: (params: any) => getOperationTypeName(params.value)
        },
        ...(type
          ? type === "Client"
            ? [
              {
                field: "produit",
                headerName: "Produit",
                editable: false,
                flex: 1,
                renderCell: (params: any) => (
                  <Link href={PATH_PRODUIT.root + "/" + params.row.product.id}>
                    {params.row.product.reference}
                  </Link>
                ),
              },
            ]
            : [
            ]
          : [
            {
              field: "produit",
              headerName: "Produit",
              editable: false,
              flex: 1,
              renderCell: (params: any) => (
                <Link
                  href={
                    PATH_PRODUIT.root +
                    "/" +
                    (params.row.product?.id ?? params.row.product_id)
                  }
                >
                  {params.row.product?.reference ??
                    params.row.product_reference}
                </Link>
              ),
            },
          ]),
        {
          field: "product_type",
          headerName: "",
          editable: false,
          flex: 0.25,
          renderCell: (params: any) => <IconProductType code={(params.row.product) ? "" + params.row.product.productType : params.row.product_type} />
        },
        {
          field: "quantity",
          headerName: "Quantité",
          editable: false,
          type: "number",
        },
        {
          field: "isSwitch",
          headerName: "Switch",
          renderHeader: () => iconSwitch,
          editable: false,
          flex: 0.25,
          renderCell: (params: any) => ((params.value === true) ? iconSwitch : <></>)
        },
        {
          field: "destination",
          headerName: "Destination",
          editable: false,
          flex: 1,
        },
        {
          field: "customs",
          headerName: "Douane",
          editable: false,
          flex: 1,
        },
        {
          field: "site",
          headerName: "Site",
          editable: false,
          flex: 1,
          valueGetter: (params: any) =>
            params.row.site?.name ?? params.row.site_name,
        },
        {
          field: "comment",
          headerName: "",
          editable: false,
          flex: 0.25,
          renderCell: (params: any) => (params.value) ? <Tooltip title={params.value}><Icon>{ICONS.withComment}</Icon></Tooltip> : null,
          align: "center",
        },
        {
          field: "author",
          headerName: "Auteur",
          editable: false,
          flex: 1,
          valueGetter: (params: any) =>
            params.row.user
              ? params.row.user.firstName + " " + params.row.user.lastName
              : params.row.user_name,
        },
        {
          field: "duplicate",
          headerName: "Doublon",
          editable: false,
          align: "center",
          renderCell: (params: any) =>
            params.value && <Alert severity="error">Doublon</Alert>,
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
      ]
    }, [type]
  );

  return (
    <List<Operation>
      columns={columns}
      searchFunc={type ? searchFunc : undefined}
      rows={operations}
      filterComponents={
        !type
          ? []
          : [<DateFilter period={period} setPeriod={setPeriod} key="df" />]
      }
      service=""
      customRedirectUrl="/operations"
      deletable={false}
      filters={[
        {
          name: "productType",
          type: "select",
          label: "Type",
          items: Object.entries(PRODUCT_TYPE_MAP).map(([key, value]) => ({ value: key, label: value }))
        },
        {
          name: "site",
          type: "select",
          label: "Site",
          items: sites.map(s => ({ value: s.id + "", label: s.name! }))
        },
      ]}
    />
  );
}

function searchFunc(op: Operation, filterValue: string) {
  return (
    (op.type === "0" ? "entree" : "sortie").indexOf(filterValue.toLowerCase()) !==
    -1 ||
    (op.client &&
      op.client.reference?.toLowerCase().indexOf(filterValue.toLowerCase()) !==
      -1) ||
    (op.product &&
      op.product.reference?.toLowerCase().indexOf(filterValue.toLowerCase()) !==
      -1) ||
    op.quantity
      ?.toString()
      ?.toLowerCase()
      .indexOf(filterValue.toLowerCase()) !== -1 ||
    (op.destination &&
      op.destination.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1) ||
    (op.customs &&
      op.customs?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1) ||
    (op.site !== "" &&
      op.site?.name?.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1) ||
    op.user?.firstName?.toLowerCase().indexOf(filterValue.toLowerCase()) !==
    -1 ||
    op.user?.lastName?.toLowerCase().indexOf(filterValue.toLowerCase()) !==
    -1 ||
    (op.duplicate === true &&
      "doublon".indexOf(filterValue.toLowerCase()) !== -1)
  );
}
