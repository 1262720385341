//Trier les attributs par ordre alphabétique
export const ICONS = {
  add: "add",
  addPhoto: "add_a_photo",
  briefcase: "business_center",
  cancel: "cancel",
  checkbox: "check_box_outline_blank",
  checkedCheckbox: "check_box",
  checkedRadio: "radio_button_checked",
  close: "close",
  cloudUpload: "cloud_upload",
  contenance: "wine_bar",
  delete: "delete",
  description: "description",
  download: "arrow_circle_download",
  downloadFile: "download",
  edit: "edit",
  excel: "download",
  expand_less: "expand_less",
  expand_more: "expand_more",
  eye: "visibility",
  eyeOff: "visibility_off",
  file: "insert_drive_file",
  filters: "filter_list",
  group: "group",
  home: "home",
  indeterminateCheckbox: "indeterminate_check_box",
  logout: "logout",
  menu: "menu",
  operation: "local_shipping",
  param: "build",
  password: "vpn_key",
  person: "person",
  prestation: "assignment",
  product: "inventory",
  radio: "radio_button_unchecked",
  save: "save",
  search: "search",
  site: "account_balance",
  stock: "warehouse",
  switch: "loop_outline",
  upload: "upload",
  warning: "warning_amber",
  nonCompliance: "back_hand",
  categoryNC: "category",
  maintenance: "alarm",
  maintenanceEvent: "build",  // "handyman"
  supplier: "engineering",
  appAndroid: "phone_android",
  left: "chevron_left",
  right: "chevron_right",
  help: "help_outline",
  migration: "settings_applications", //"perm_data_setting",
  photo: "camera_alt", // "photo_camera",
  attachment: "attach_file",
  check: "check",
  lock: "lock",
  unlock: "lock_open",
  productType: "hexagon", // "format_size", //"dashboard",
  noAccess: "highlight_off",
  //attachment:"paperclip"
  withComment: "chat_outline",  //"speaker_notes_outline",

};

export default ICONS;
