// routes
import { Role } from "../../@types/user";
import ICONS from "../../assets/icons";
import useAuth from "../../hooks/useAuth";
import {
  PATH_ADMIN,
  PATH_AUTH,
  PATH_CLIENT,
  PATH_OPERATION,
  PATH_PRESTATION,
  PATH_PRODUIT,
  PATH_NON_COMPLIANCE,
  PATH_REGLAGES,
  PATH_STOCK,
  PATH_MAINTENANCE_EVENT,
  PATH_APK
} from "../../routes/paths";

// ----------------------------------------------------------------------
export default function navConfig(user: any) {
  //const { user } = useAuth();
  //const auth = useAuth();
  return [
    {
      subheader: "general",
      items: [
        {
          title: "Nouveau",
          icon: ICONS.add,
          children: [
            { title: "Nouveau produit", path: PATH_PRODUIT.new, icon: ICONS.product, roles: [Role.ROLE_MANAGER] },
            { title: "Nouvelle prestation", path: PATH_PRESTATION.new, icon: ICONS.prestation },
            { title: "Nouvelle opération", path: PATH_OPERATION.new, icon: ICONS.operation },
            { title: "Nouveau client", path: PATH_CLIENT.new, icon: ICONS.person, roles: [Role.ROLE_MANAGER] },
            { title: "Nouveau switch de produits", path: PATH_PRODUIT.switch, icon: ICONS.switch },
            { title: "Nouvelle non-conformité", path: PATH_NON_COMPLIANCE.new, icon: ICONS.nonCompliance },
            { title: "Nouvelle opération de maintenance", path: PATH_MAINTENANCE_EVENT.new, icon: ICONS.maintenanceEvent, roles: [Role.ROLE_MANAGER] },
          ],
        },
        {
          title: "Liste",
          icon: ICONS.menu,
          children: [
            { title: "Liste des produits", path: PATH_PRODUIT.root, icon: ICONS.product },
            { title: "Liste des prestations", path: PATH_PRESTATION.root, icon: ICONS.prestation },
            { title: "Liste des opérations", path: PATH_OPERATION.root, icon: ICONS.operation },
            { title: "Liste des clients", path: PATH_CLIENT.root, icon: ICONS.person },
            { title: "Reporting des stocks", path: PATH_STOCK.root, icon: ICONS.stock },
            { title: "Liste des non-conformités", path: PATH_NON_COMPLIANCE.root, icon: ICONS.nonCompliance },
            { title: "Liste des opération de maintenance", path: PATH_MAINTENANCE_EVENT.root, icon: ICONS.maintenanceEvent },
          ],
        },
        {
          title: "Gestion",
          icon: ICONS.briefcase,
          roles: [Role.ROLE_MANAGER],
          children: [
            { title: "Gestion des utilisateurs ", path: PATH_ADMIN.user.root, icon: ICONS.group },
            { title: "Gestion des sites", path: PATH_ADMIN.site.root, icon: ICONS.site },
            { title: "Gestion des types de prestation", path: PATH_ADMIN.type_prestation.root, icon: ICONS.prestation },
            { title: "Gestion des types de produit", path: PATH_ADMIN.product_type.root, icon: ICONS.productType },
            { title: "Gestion des descriptions", path: PATH_ADMIN.description, icon: ICONS.description },
            { title: "Gestion des contenances", path: PATH_ADMIN.contenance, icon: ICONS.contenance },
            { title: "Gestion des catégories non-conformité", path: PATH_ADMIN.categoryNC, icon: ICONS.categoryNC },
            { title: "Gestion des prestataires", path: PATH_ADMIN.supplier, icon: ICONS.supplier },
            { title: "Gestion des maintenances", path: PATH_ADMIN.maintenance.root, icon: ICONS.maintenance },
            { title: "Gestion des modèles d'export", path: PATH_ADMIN.file_template.root, icon: ICONS.excel },
            { title: "Mise à jour des données", path: PATH_ADMIN.migration, icon: ICONS.migration },
          ],
        },
        {
          title: (user) ? user.firstName + " " + user.lastName : "Compte",
          icon: ICONS.person,
          children: [
            { title: "Réglages ", path: PATH_REGLAGES, icon: ICONS.param },
            { title: "Appli mobile ", path: PATH_APK, icon: ICONS.appAndroid },
            { title: "Mot de passe", path: PATH_AUTH.changePassword, icon: ICONS.password },
            { title: "Déconnexion", path: PATH_AUTH.logout, icon: ICONS.logout },
          ],
        },
      ]
    }
  ];
}

