// @mui
import { alpha } from '@mui/material/styles';
import { IconButton, Stack, Typography, Box, Icon, Link } from '@mui/material';
// utils
import { fData } from '../../../utils/formatNumber';
//
import FileThumbnail, { fileData } from '../../file-thumbnail';
//
import { UploadProps } from '../types';
import ICONS from '../../../assets/icons';

import { Media } from '../../../@types/media';
import FilePreview from './FilePreview';

// ----------------------------------------------------------------------

export default function MultiFilePreview(props: UploadProps) {
  const { thumbnail, files, onRemove, sx } = props
  if (!files?.length) {
    return null;
  }

  return (
    <Box>
      {files.map((file: string | File | Media, index) => <FilePreview file={file} index={index} thumbnail={thumbnail} onRemove={onRemove} />)}
    </Box >
  );
}
